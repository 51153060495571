const Navbar = () => {
  const links = [
    {
      name: "Home",
      href: "#",
      key: 1,
    },
    {
      name: "Markets",
      href: "#",
      key: 2,
    },
    {
      name: "About",
      href: "#",
      key: 3,
    },
  ];

  return (
    <nav className="bg-white px-2 sm:px-5 py-2.5 rounded dark:bg-gray-900 px-6">
      <div className="container flex flex-wrap items-center justify-between mx-auto ">
        <a
          href="https://www.ziliax.com/"
          className="flex items-center border-2 border-black bg-black text-white hover:bg-white hover:text-black"
        >
          <span className="self-center text-xl font-semibold whitespace-nowrap px-2">
            Ziliax
          </span>
        </a>
        <button
          data-collapse-toggle="navbar-default"
          type="button"
          className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          aria-controls="navbar-default"
          aria-expanded="false"
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-6 h-6"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
        <div className="hidden w-full md:block md:w-auto" id="navbar-default">
          <ul className="flex flex-col p-4 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
            {links.map((link) => (
              <li key={link.key}>
                <a
                  href="#"
                  className="block py-2 pl-3 pr-4 text-black rounded md:p-0"
                  aria-current="page"
                >
                  {link.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
};
export default Navbar;
