import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import News from "./components/news/News";
import Article from "./components/article/Article";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<News />} />
        <Route exact path="/article" element={<Article />} />
      </Routes>
    </Router>
  );
}

export default App;
