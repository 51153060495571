import React from "react";

import Navbar from "../news/navbar/Navbar";

const ArticlePage = ({ title, description, paragraphs }) => {
  return (
    <div>
      <Navbar />
      <div className="grid bg-white border-gray-200 px-5 py-2.5 rounded dark:bg-gray-900 justify-items-center ">
        <div className="container mx-right pt-4 max-w-3xl px-0.5 py-1">
          <h1 className="text-sm text-black">
            {" "}
            2022-01-02 /{" "}
            <a className="hover:underline" href="#">
              #CryptoCurrecny
            </a>{" "}
          </h1>
        </div>
        <div className="container mx-right max-w-3xl">
          <h1 className="text-3xl font-bold dark:text-white"> {title} </h1>
        </div>
        <div className="container mx-right pt-4 max-w-3xl">
          <p class="text-xl font-bold text-gray-600"> {description} </p>
        </div>

        <div className="container mx-right pt-4 max-w-3xl">
          {paragraphs.map((paragraph) => (
            <p className="text-lg mb-4 py-1 text-black"> {paragraph} </p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ArticlePage;
